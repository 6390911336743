import { createApp } from 'vue'
import App from './App.vue'
import FormatNumber from './plugins/FormatNumber'
import RoundNumber from './plugins/RoundNumber'
import LocationIdColor from './plugins/LocationIdColor'
import CurrencyHTMLElement from './plugins/CurrencyHTMLElement'
import FloorNumToName from './plugins/FloorNumToName'
import FloorNumToNameShort from './plugins/FloorNumToNameShort'
import CostColorClass from './plugins/CostColorClass'
import CostColorHex from './plugins/CostColorHex'
import BuyerStatusColor from './plugins/BuyerStatusColor'
import NumberToPercent from './plugins/NumberToPercent'
import ImagePath from './plugins/ImagePath'
import ImageHeroURL from './plugins/ImageHeroURL'
import DateToQuarterAndYear from './plugins/DateToQuarterAndYear'
import router from './router'
import store from './store'
import './index.css'
import { createAuth0 } from '@auth0/auth0-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueGtag from 'vue-gtag'
import { MotionPlugin } from '@vueuse/motion'

// Import material design icons helper css
import 'vue-material-design-icons/styles.css'
// Import material design icons
import FileDocumentMultiple from 'vue-material-design-icons/FileDocumentMultiple.vue'
import EmoticonHappyOutline from 'vue-material-design-icons/EmoticonHappyOutline.vue'
import EmoticonNeutralOutline from 'vue-material-design-icons/EmoticonNeutralOutline.vue'
import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline.vue'
import FileDownload from 'vue-material-design-icons/FileDownload.vue'
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue'
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'
import SetSquare from 'vue-material-design-icons/SetSquare.vue'
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue'
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import ViewAgenda from 'vue-material-design-icons/ViewAgenda.vue'
import ViewAgendaOutline from 'vue-material-design-icons/ViewAgendaOutline.vue'
import MapOutline from 'vue-material-design-icons/MapOutline.vue'
import FormatListBulletedSquare from 'vue-material-design-icons/FormatListBulletedSquare.vue'
import FormatListText from 'vue-material-design-icons/FormatListText.vue'
import ViewHeadline from 'vue-material-design-icons/ViewHeadline.vue'
import ViewSequential from 'vue-material-design-icons/ViewSequential.vue'
import Eye from 'vue-material-design-icons/Eye.vue'
import EyeOff from 'vue-material-design-icons/EyeOff.vue'
import ImageFilterVintage from 'vue-material-design-icons/ImageFilterVintage.vue'
import Wallpaper from 'vue-material-design-icons/Wallpaper.vue'
import FaceRecognition from 'vue-material-design-icons/FaceRecognition.vue'
import StorefrontOutline from 'vue-material-design-icons/StorefrontOutline.vue'
import RulerSquare from 'vue-material-design-icons/RulerSquare.vue'
import CityVariantOutline from 'vue-material-design-icons/CityVariantOutline.vue'
import CrosshairsGps from 'vue-material-design-icons/CrosshairsGps.vue'
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Menu from 'vue-material-design-icons/Menu.vue'
import LoginVariant from 'vue-material-design-icons/LoginVariant.vue'
import HomeOutline from 'vue-material-design-icons/HomeOutline.vue'
import Plus from 'vue-material-design-icons/Plus.vue'
import PlusThick from 'vue-material-design-icons/PlusThick.vue'
import MinusThick from 'vue-material-design-icons/MinusThick.vue'
import AccountTieOutline from 'vue-material-design-icons/AccountTieOutline.vue'
import LogoutVariant from 'vue-material-design-icons/LogoutVariant.vue'
import MapLegend from 'vue-material-design-icons/MapLegend.vue'
import LayersOutline from 'vue-material-design-icons/LayersOutline.vue'
import StretchToPageOutline from 'vue-material-design-icons/StretchToPageOutline.vue'
import CellphoneText from 'vue-material-design-icons/CellphoneText.vue'
import Filter from 'vue-material-design-icons/Filter.vue'
import FilterOff from 'vue-material-design-icons/FilterOff.vue'
import FilterMenu from 'vue-material-design-icons/FilterMenu.vue'
import FilterMenuOutline from 'vue-material-design-icons/FilterMenuOutline.vue'
import Close from 'vue-material-design-icons/Close.vue'
import FloorPlan from 'vue-material-design-icons/FloorPlan.vue'
import RotateRight from 'vue-material-design-icons/RotateRight.vue'
import CircleEditOutline from 'vue-material-design-icons/CircleEditOutline.vue'
import Store from 'vue-material-design-icons/Store.vue'
import StorePlus from 'vue-material-design-icons/StorePlus.vue'
import StoreRemove from 'vue-material-design-icons/StoreRemove.vue'
import Image from 'vue-material-design-icons/Image.vue'
import ImageMultiple from 'vue-material-design-icons/ImageMultiple.vue'
import LinkVariant from 'vue-material-design-icons/LinkVariant.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import CodeLessThan from 'vue-material-design-icons/CodeLessThan.vue'
import Numeric10Box from 'vue-material-design-icons/Numeric10Box.vue'
import Numeric5Box from 'vue-material-design-icons/Numeric5Box.vue'
import Numeric3Box from 'vue-material-design-icons/Numeric3Box.vue'
import Numeric2Box from 'vue-material-design-icons/Numeric2Box.vue'
import Numeric1Box from 'vue-material-design-icons/Numeric1Box.vue'
import Cog from 'vue-material-design-icons/Cog.vue'
import Tag from 'vue-material-design-icons/Tag.vue'
import MapPlus from 'vue-material-design-icons/MapPlus.vue'
import Map from 'vue-material-design-icons/Map.vue'
import CardAccountDetailsOutline from 'vue-material-design-icons/CardAccountDetailsOutline.vue'
import CircleSmall from 'vue-material-design-icons/CircleSmall.vue'
import DotsHorizontal from 'vue-material-design-icons/DotsHorizontal.vue'
import OrderBoolDescendingVariant from 'vue-material-design-icons/OrderBoolDescendingVariant.vue'
import Reload from 'vue-material-design-icons/Reload.vue'
import ReloadAlert from 'vue-material-design-icons/ReloadAlert.vue'
import Cogs from 'vue-material-design-icons/Cogs.vue'
import Upload from 'vue-material-design-icons/Upload.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import SlashForwardBox from 'vue-material-design-icons/SlashForwardBox.vue'
import SignRealEstate from 'vue-material-design-icons/SignRealEstate.vue'
import MapMarker from 'vue-material-design-icons/MapMarker.vue'
import MapMarkerMultiple from 'vue-material-design-icons/MapMarkerMultiple.vue'
import CashRegister from 'vue-material-design-icons/CashRegister.vue'
import Alpha from 'vue-material-design-icons/Alpha.vue'
import AccountGroup from 'vue-material-design-icons/AccountGroup.vue'
import Checkerboard from 'vue-material-design-icons/Checkerboard.vue'
import Account from 'vue-material-design-icons/Account.vue'
import Merge from 'vue-material-design-icons/Merge.vue'
import Poll from 'vue-material-design-icons/Poll.vue'
import ViewListOutline from 'vue-material-design-icons/ViewListOutline.vue'
import ViewList from 'vue-material-design-icons/ViewList.vue'
import DeleteCircle from 'vue-material-design-icons/DeleteCircle.vue'
import PencilCircle from 'vue-material-design-icons/PencilCircle.vue'
import PlusCircle from 'vue-material-design-icons/PlusCircle.vue'
import RomanNumeral1 from 'vue-material-design-icons/RomanNumeral1.vue'
import RomanNumeral2 from 'vue-material-design-icons/RomanNumeral2.vue'
import RomanNumeral3 from 'vue-material-design-icons/RomanNumeral3.vue'
import RomanNumeral4 from 'vue-material-design-icons/RomanNumeral4.vue'
import HeadSnowflakeOutline from 'vue-material-design-icons/HeadSnowflakeOutline.vue'
import SelectGroup from 'vue-material-design-icons/SelectGroup.vue'
import LandPlots from 'vue-material-design-icons/LandPlots.vue'
import TargetVariant from 'vue-material-design-icons/TargetVariant.vue'
import Shimmer from 'vue-material-design-icons/Shimmer.vue'
import TagText from 'vue-material-design-icons/TagText.vue'
import EmailFastOutline from 'vue-material-design-icons/EmailFastOutline.vue'
import TicketConfirmationOutline from 'vue-material-design-icons/TicketConfirmationOutline.vue'
import Pulse from 'vue-material-design-icons/Pulse.vue'
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import FilePdfBox from 'vue-material-design-icons/FilePdfBox.vue'
import Printer from 'vue-material-design-icons/Printer.vue'
import Devices from 'vue-material-design-icons/Devices.vue'
import CommentOutline from 'vue-material-design-icons/CommentOutline.vue'
import CommentOffOutline from 'vue-material-design-icons/CommentOffOutline.vue'
import CheckBold from 'vue-material-design-icons/CheckBold.vue'
import Artboard from 'vue-material-design-icons/Artboard.vue'
import Robot from 'vue-material-design-icons/Robot.vue'
import Star from 'vue-material-design-icons/Star.vue'

// Set base URL of axios to the local /api/ using window-location value
if (window.location.hostname === 'localhost') {
  var apiEndPoint = 'https://store.kirsch.agency/api/' // store prd
} else {
  var apiEndPoint = 'https://store.kirsch.agency/api/' // store prd
  // var apiEndPoint = window.location.origin + '/api/' // production
}
axios.defaults.baseURL = apiEndPoint

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(
  createAuth0({
    domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
    client_id: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
    redirect_uri: process.env.VUE_APP_DOMAINURL_REDIRECT
  })
)
app.use(MotionPlugin)
app.use(FormatNumber)
app.use(RoundNumber)
app.use(LocationIdColor)
app.use(CurrencyHTMLElement)
app.use(FloorNumToName)
app.use(FloorNumToNameShort)
app.use(CostColorClass)
app.use(CostColorHex)
app.use(BuyerStatusColor)
app.use(NumberToPercent)
app.use(ImagePath)
app.use(ImageHeroURL)
app.use(DateToQuarterAndYear)
app.use(VueGoogleMaps, {
  load: {
    version: 3.53,
    key: 'AIzaSyD6OhPsnaXhHKiaBHZaan5cjs3aBEemV2w',
    libraries: 'places, drawing'
  }
})
app.use(
  VueGtag,
  {
    config: { id: 'G-8F3B0HEPJV' }
  },
  router
)
app.component('file-document-multiple-icon', FileDocumentMultiple)
app.component('emoticon-happy-outline-icon', EmoticonHappyOutline)
app.component('emoticon-neutral-outline-icon', EmoticonNeutralOutline)
app.component('emoticon-sad-outline-icon', EmoticonSadOutline)
app.component('file-download-icon', FileDownload)
app.component('content-copy-icon', ContentCopy)
app.component('open-in-new-icon', OpenInNew)
app.component('set-square-icon', SetSquare)
app.component('chevron-right-icon', ChevronRight)
app.component('chevron-up-icon', ChevronUp)
app.component('chevron-down-icon', ChevronDown)
app.component('view-agenda-icon', ViewAgenda)
app.component('view-agenda-outline-icon', ViewAgendaOutline)
app.component('map-outline-icon', MapOutline)
app.component('format-list-bulleted-square-icon', FormatListBulletedSquare)
app.component('format-list-text-icon', FormatListText)
app.component('view-headline-icon', ViewHeadline)
app.component('view-sequential-icon', ViewSequential)
app.component('eye-icon', Eye)
app.component('eye-off-icon', EyeOff)
app.component('image-filter-vintage-icon', ImageFilterVintage)
app.component('wallpaper-icon', Wallpaper)
app.component('face-recognition-icon', FaceRecognition)
app.component('storefront-outline-icon', StorefrontOutline)
app.component('ruler-square-icon', RulerSquare)
app.component('city-variant-outline-icon', CityVariantOutline)
app.component('crosshairs-gps-icon', CrosshairsGps)
app.component('arrow-left-icon', ArrowLeft)
app.component('arrow-right-icon', ArrowRight)
app.component('menu-icon', Menu)
app.component('login-variant-icon', LoginVariant)
app.component('home-outline-icon', HomeOutline)
app.component('plus-icon', Plus)
app.component('plus-thick-icon', PlusThick)
app.component('minus-thick-icon', MinusThick)
app.component('account-tie-outline-icon', AccountTieOutline)
app.component('logout-variant-icon', LogoutVariant)
app.component('map-legend-icon', MapLegend)
app.component('layers-outline-icon', LayersOutline)
app.component('stretch-to-page-outline-icon', StretchToPageOutline)
app.component('cellphone-text-icon', CellphoneText)
app.component('filter-icon', Filter)
app.component('filter-off-icon', FilterOff)
app.component('filter-menu-icon', FilterMenu)
app.component('filter-menu-outline-icon', FilterMenuOutline)
app.component('close-icon', Close)
app.component('floor-plan-icon', FloorPlan)
app.component('rotate-right-icon', RotateRight)
app.component('circle-edit-outline-icon', CircleEditOutline)
app.component('store-icon', Store)
app.component('store-plus-icon', StorePlus)
app.component('store-remove-icon', StoreRemove)
app.component('image-icon', Image)
app.component('image-multiple-icon', ImageMultiple)
app.component('link-variant-icon', LinkVariant)
app.component('pencil-icon', Pencil)
app.component('code-less-than-icon', CodeLessThan)
app.component('numeric-10-box-icon', Numeric10Box)
app.component('numeric-5-box-icon', Numeric5Box)
app.component('numeric-3-box-icon', Numeric3Box)
app.component('numeric-2-box-icon', Numeric2Box)
app.component('numeric-1-box-icon', Numeric1Box)
app.component('cog-icon', Cog)
app.component('tag-icon', Tag)
app.component('map-plus-icon', MapPlus)
app.component('map-icon', Map)
app.component('card-account-details-outline-icon', CardAccountDetailsOutline)
app.component('circle-small-icon', CircleSmall)
app.component('dots-horizontal-icon', DotsHorizontal)
app.component('order-bool-descending-variant-icon', OrderBoolDescendingVariant)
app.component('reload-icon', Reload)
app.component('reload-alert-icon', ReloadAlert)
app.component('cogs-icon', Cogs)
app.component('upload-icon', Upload)
app.component('delete-icon', Delete)
app.component('slash-forward-box-icon', SlashForwardBox)
app.component('sign-real-estate-icon', SignRealEstate)
app.component('map-marker-icon', MapMarker)
app.component('map-marker-multiple-icon', MapMarkerMultiple)
app.component('cash-register-icon', CashRegister)
app.component('alpha-icon', Alpha)
app.component('account-group-icon', AccountGroup)
app.component('checkerboard-icon', Checkerboard)
app.component('account-icon', Account)
app.component('merge-icon', Merge)
app.component('poll-icon', Poll)
app.component('view-list-outline-icon', ViewListOutline)
app.component('view-list-icon', ViewList)
app.component('delete-circle-icon', DeleteCircle)
app.component('pencil-circle-icon', PencilCircle)
app.component('plus-circle-icon', PlusCircle)
app.component('roman-numeral-1-icon', RomanNumeral1)
app.component('roman-numeral-2-icon', RomanNumeral2)
app.component('roman-numeral-3-icon', RomanNumeral3)
app.component('roman-numeral-4-icon', RomanNumeral4)
app.component('head-snowflake-outline-icon', HeadSnowflakeOutline)
app.component('select-group-icon', SelectGroup)
app.component('land-props-icon', LandPlots)
app.component('target-variant-icon', TargetVariant)
app.component('shimmer-icon', Shimmer)
app.component('tag-text-icon', TagText)
app.component('email-fast-outline-icon', EmailFastOutline)
app.component('ticket-confirmation-outline-icon', TicketConfirmationOutline)
app.component('pulse-icon', Pulse)
app.component('help-circle-icon', HelpCircle)
app.component('file-pdf-box-icon', FilePdfBox)
app.component('printer-icon', Printer)
app.component('devices-icon', Devices)
app.component('comment-outline-icon', CommentOutline)
app.component('comment-off-outline-icon', CommentOffOutline)
app.component('check-bold-icon', CheckBold)
app.component('artboard-icon', Artboard)
app.component('robot-icon', Robot)
app.component('star-icon', Star)

app.mount('#app')
